import { LandingPageCheck } from '@/components/icons';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useInView } from 'motion/react';
import Image from 'next/image';

const TRENDING_ITEMS = [
  {
    id: 1,
    title: 'Xử lý ngôn ngữ tự nhiên NLP',
    desc: 'Tăng cường khả năng tương tác, phân tích cảm xúc và tự động xử lý tài liệu. ',
    items: [
      'Ứng dụng deep learning để phân loại văn bản và trích xuất thông tin chuyên sâu. ',
      'Generative AI hỗ trợ sáng tạo nội dung tự nhiên',
      'Tích hợp vào AI agents (chatbots, callbots) tự động hóa quy trình giao tiếp.',
      'ASR chuyển đổi giọng nói thành văn bản, tối ưu hóa phân tích dữ liệu âm thanh cho các tác vụ xử lý ngôn ngữ.',
    ],
  },
  {
    id: 2,
    title: 'Computer vision',
    desc: 'Nhận diện, phân tích và hiểu thông tin từ hình ảnh và video, tự động hóa quy trình, nâng cao khả năng kiểm tra chất lượng và bảo mật.',
    items: [
      'Sử dụng CNNs trích xuất đặc trưng hình ảnh (cạnh, kết cấu, hình dạng)',
      'Kết hợp  R-CNN và YOLO tối ưu nhận dạng và phân loại đối tượng. ',
      'Xử lý hình ảnh và video (segmentation, edge detection, object tracking) cho phép phân tích hình ảnh động và tĩnh một cách chi tiết, chính xác.',
    ],
  },
  {
    id: 3,
    title: 'Machine Learning',
    desc: 'Phân tích dữ liệu, dự đoán xu hướng, phát hiện gian lận, tự động hóa và cá nhân hóa các tác vụ, kiểm soát rủi ro.',
    items: [
      'Phân tích dữ liệu sử dụng các công nghệ Data Mining, Big Data',
      'Sử dụng các mô hình xác suất, Machine Learning, Deep Learning để phân tích và dự đoán xu hướng',
      'Tự động hóa các quy trình ra quyết định dựa trên dữ liệu',
    ],
  },
];

const TIMEOUT = 10000;

export const TrendingFeature: React.FC = () => {
  const [active, setActive] = useState(1);
  const containerRef = useRef(null);
  const isInView = useInView(containerRef);
  const id = useRef(null);

  useEffect(() => {
    if (!isInView) {
      id.current && clearInterval(id.current);
      return;
    }

    id.current = setInterval(() => {
      setActive((prev) => (prev % 3) + 1);
    }, TIMEOUT);

    return () => {
      clearInterval(id.current);
    };
  }, [isInView]);

  const onChangeTab = (tabId: number) => {
    setActive(tabId);
    clearInterval(id.current);
    id.current = setInterval(() => {
      setActive((prev) => (prev % 3) + 1);
    }, TIMEOUT);
  };

  return (
    <div ref={containerRef} className="w-full flex flex-col lg:flex-row justify-between gap-9  pb-[80px]">
      <div className="relative flex flex-col gap-8 justify-between w-full pl-4 lg:pl-10 h-[600px] xl:h-[700px]">
        <div className="absolute top-0 left-0 w-[2px] h-[100%] bg-th-gray-30 rounded-lg">
          <div
            className={cn(
              'absolute h-[50%] xl:h-[45%] top-0 left-0 w-[3px] bg-th-text transition-all duration-500',
              {
                'top-[24%] xl:top-[28%]': active === 2,
                'top-[53%] xl:top-[66%] xl:!h-[35%]': active === 3,
              }
            )}
          />
        </div>

        {TRENDING_ITEMS.map((item, index) => (
          <div
            key={index}
            className={cn(
              'w-full h-full relative flex flex-col gap-3 lg:gap-5 transition-all cursor-pointer',
              {
                'text-th-gray-400': active !== item.id,
                'justify-start': item.id === 1 || (item.id === 2 && active === 3),
                'justify-end': item.id === 3 || (item.id === 2 && active === 1),
              }
            )}
            onClick={() => {
              onChangeTab(item.id);
            }}
          >
            <div className="font-semibold text-xl">{item.title}</div>
            <div className="flex flex-col gap-3 text-sm lg:text-base">
              <span>{item.desc}</span>
              <div
                className={cn('flex flex-col gap-2 mt-2', {
                  hidden: active !== item.id,
                })}
              >
                {item.items.map((i, idx) => (
                  <div key={idx} className="flex gap-2 items-start">
                    <LandingPageCheck className="shrink-0 mt-[2px]" />
                    {i}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full h-[600px] xl:h-[700px] relative rounded-3xl overflow-hidden">
        <Image
          src="/imgs/5_optimized.gif"
          alt="Landing image"
          priority
          fill
          className={cn('object-cover', {
            hidden: active !== 1,
          })}
        />
        <Image
          src="/imgs/6_optimized.gif"
          alt="Landing image"
          priority
          fill
          className={cn('object-cover', {
            hidden: active !== 2,
          })}
        />
        <Image
          src="/imgs/7_optimized.gif"
          alt="Landing image"
          priority
          fill
          className={cn('object-cover', {
            hidden: active !== 3,
          })}
        />
      </div>
    </div>
  );
};
