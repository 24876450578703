import React from 'react';
import { motion } from 'motion/react';

export function ColorfulText({ text, delay }: { text: string; delay?: number }) {
  return text.split(' ').map((text, indexText) => (
    <span className="inline-block " key={indexText}>
      {text.split('').map((char, index) => (
        <motion.span
          key={`${char}-${index}`}
          initial={{
            y: 0,
          }}
          animate={{
            color: 'var(--color-primary-alt)',
            filter: ['blur(0px)', 'blur(2px)', 'blur(0px)'],
          }}
          transition={{
            duration: 0.4,
            delay: indexText * 0.25 + index * 0.05 + delay,
          }}
          className="inline-block whitespace-pre"
        >
          {char}
        </motion.span>
      ))}
    </span>
  ));
}
