import { LandingPageLine, LandingPageMulLines } from '@/components/icons';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

const TEXTS = ['Tự động hóa', 'Nâng cao trải nghiệm khách hàng', 'Cá nhân hóa'];

interface IProps {
  isAlternate?: boolean;
}

export const Banner: React.FC<IProps> = ({ isAlternate }) => {
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % TEXTS.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className={cn('pb-8 lg:flex hidden items-center', {
        '!flex lg:!hidden': isAlternate,
      })}
    >
      <LandingPageMulLines />
      <div className="relative w-[320px] h-full">
        <AnimatePresence>
          {TEXTS.map((text, index) => {
            return index === currentText ? (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: '-50%', opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 400,
                  damping: 40,
                }}
                className="text-th-gray-300 text-lg px-4 absolute top-1/2 left-0 w-full"
              >
                {text}
              </motion.div>
            ) : null;
          })}
        </AnimatePresence>
      </div>
      <LandingPageLine />
    </div>
  );
};
