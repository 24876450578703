interface BlockPersonalProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

export const BlockPersonal: React.FC<BlockPersonalProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-3 p-6">
      <div className="font-semibold text-2xl">{title}</div>
      <div className="text-th-gray-400 leading-[24px]">{children}</div>
    </div>
  );
};
