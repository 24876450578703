import { ArrowLeftAlt } from '@/components/icons';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import { TrendingFeature } from './components/TrandingFeature';
import { BlockPersonal } from './components/BlockPersonal';
import { Title } from './components/Title';
import { LandingpageHeader } from '@/components/common/landingpage/LandingpageHeader';
import { Footer } from '@/components/common/landingpage/Footer';
import { News } from '@/components/common/landingpage/News';
import { logoAnim, solution1Anim, solution2Anim, solution3Anim } from './animations';
import Link from 'next/link';
import { Banner } from './components/Banner';
import { InfiniteMovingCards } from './components/MovingCard';
import { useInView } from 'motion/react';
import { ColorfulText } from '@/components/common/ColorfulText';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

const LIST_CARDS = [
  {
    src: '/imgs/partner/1.png',
  },
  {
    src: '/imgs/partner/2.png',
  },
  {
    src: '/imgs/partner/3.png',
  },
  {
    src: '/imgs/partner/4.png',
  },
  {
    src: '/imgs/partner/5.png',
  },
  {
    src: '/imgs/partner/6.png',
  },
  {
    src: '/imgs/partner/7.png',
  },
];

export const LandingPage = ({ news }) => {
  const lottieRef1 = useRef(null);
  const lottieRef2 = useRef(null);
  const lottieRef3 = useRef(null);
  const containerRef = useRef(null);
  const isInview = useInView(containerRef);

  useEffect(() => {
    if (isInview) {
      lottieRef1.current?.play();
      lottieRef2.current?.play();
      lottieRef3.current?.play();
    } else {
      lottieRef1.current?.stop();
      lottieRef2.current?.stop();
      lottieRef3.current?.stop();
    }
  }, [isInview]);

  return (
    <div className="font-landing-page relative font-poppins">
      <LandingpageHeader />
      <section className="min-h-dvh lg:h-dvh relative -mt-[78px] pt-[78px]">
        <div className="absolute inset-0 w-full h-full select-none pointer-events-none">
          <Image src="/imgs/landing_img.png" fill alt="Landing image" className="absolute inset-0 z-0" />
        </div>
        <div className="w-[90%] xl:w-[75%] 2xl:w-[60%] h-full relative z-10 mx-auto flex justify-center items-center !pb-20">
          <div className="flex flex-col lg:flex-row justify-between w-full relative z-10 gap-4 xl:gap-16 mt-8">
            <div className="flex flex-col h-full 2xl:justify-between max-w-[600px]">
              <h1 className="text-th-background text-4xl xl:text-6xl !mb-10 font-semibold whitespace-nowrap">
                Nền tảng giải pháp
              </h1>

              <div className="text-th-background text-3xl 2xl:text-4xl mb-8 leading-10 flex flex-wrap gap-2 ">
                <ColorfulText text="ứng dụng AI" delay={0.5} /> và{' '}
                <ColorfulText text="công nghệ dữ liệu" delay={2} /> <span>để tạo</span> nên{' '}
                <ColorfulText text="những giải pháp riêng" delay={3.5} /> <span>cho doanh</span> nghiệp
              </div>

              <Banner />

              <Link
                className="hidden xl:flex items-center justify-center text-th-text bg-th-primary-alt rounded-3xl py-2 w-[200px] text-center "
                href="/signup"
              >
                Tư vấn ngay <ArrowLeftAlt className="rotate-180 scale-75" />
              </Link>
            </div>
            <Lottie animationData={logoAnim} loop={false} className="shrink-0" />

            <Banner isAlternate />

            <Link
              className="xl:hidden flex items-center justify-center text-th-text bg-th-primary-alt rounded-3xl py-2 w-[200px] text-center "
              href="/signup"
            >
              Tư vấn ngay <ArrowLeftAlt className="rotate-180 scale-75" />
            </Link>
          </div>
        </div>
      </section>

      <section className="w-[90%] xl:w-[75%] 2xl:w-[60%] mx-auto">
        <InfiniteMovingCards speed="slow" items={LIST_CARDS} />

        <div className="w-full h-[1px] bg-th-gray-80 " />

        <Title className="py-[80px]">
          Hệ sinh thái <br className="lg:hidden block" /> giải pháp
        </Title>

        <div ref={containerRef} className="flex flex-col xl:flex-row  gap-9 w-full min-h-[825px]">
          <div className="bg-th-gray-30 rounded-lg flex flex-col justify-between w-full relative mx-auto overflow-hidden">
            <BlockPersonal title="Tự động hóa">
              Tự động hóa quy trình quản trị doanh nghiệp từ bán hàng, vận hành, chăm sóc khách hàng đến hậu
              cần, giúp tinh giản quy trình, giảm chi phí vận hành và tối đa hóa hiệu quả kinh doanh.
            </BlockPersonal>

            <Lottie lottieRef={lottieRef1} animationData={solution2Anim} loop />
          </div>

          <div className="w-full h-full flex flex-col gap-9">
            <div className="bg-th-gray-30 rounded-lg w-full flex flex-col justify-between relative mx-auto overflow-hidden">
              <BlockPersonal title="Nâng cao trải nghiệm khách hàng">
                Tạo ra một trải nghiệm liền mạch thông qua phân tích hành vi khách hàng, từ đó cá nhân hóa
                từng điểm chạm, tối ưu hành trình khách hàng, giúp tăng mức độ hài lòng và giữ chân khách
                hàng.
              </BlockPersonal>

              <Lottie lottieRef={lottieRef2} animationData={solution1Anim} loop />
            </div>

            <div className="bg-th-gray-30 rounded-lg w-full flex flex-col justify-between relative mx-auto overflow-hidden">
              <BlockPersonal title="Cá nhân hoá">
                Cá nhân hóa sản phẩm, dịch vụ, kịch bản bán hàng và chăm sóc khách hàng theo từng nhóm đối
                tượng, giúp dự đoán nhu cầu, tối ưu sản xuất, nâng cao hiệu quả kinh doanh
              </BlockPersonal>

              <Lottie lottieRef={lottieRef3} animationData={solution3Anim} loop />
            </div>
          </div>
        </div>

        <Title className="pt-[160px] pb-[80px]">
          Hạ tầng <br className="lg:hidden block" /> công nghệ{' '}
          <span className="hidden lg:inline">
            tăng cường khả <br /> năng tương tác, nhận diện, phân tích, dự <br /> đoán xu hướng{' '}
          </span>
        </Title>

        <TrendingFeature />
      </section>

      <section className="bg-th-gray-50 py-[80px] overflow-hidden">
        <div className="w-[90%] xl:w-[75%] 2xl:w-[60%] mx-auto">
          <h2 className="text-4xl font-semibold">Tin Tức</h2>
          <News news={news} />
        </div>
      </section>

      <Footer />
    </div>
  );
};
