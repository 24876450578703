import { ArrowLeftAlt } from '@/components/icons';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Carousel, CarouselContent, CarouselItem, CarouselNavigation } from '../Carousel';

export const News = ({ news }) => {
  return (
    <div>
      <Carousel>
        <CarouselContent className="-ml-4 flex items-center">
          {news?.map((item, index) => (
            <CarouselItem
              key={index}
              className="flex flex-col gap-4 justify-between h-full basis-[100%] md:basis-1/2 xl:basis-1/3 pl-4"
            >
              <div className="bg-th-background rounded-xl">
                <div>
                  <div className="w-full h-[200px] relative select-none">
                    <Image src={item.image} fill className="rounded-t-xl object-cover" alt="News" />
                  </div>
                  <div className="flex flex-col gap-4 p-4">
                    <Link
                      href={`/news/${item.slug}`}
                      className="text-th-text font-semibold text-xl line-clamp-3 h-[84px]"
                    >
                      {item.title}
                    </Link>
                    <div className="text-th-gray-500 line-clamp-4 h-[100px] text-base">{item.desc}</div>
                  </div>
                </div>
                <Link
                  href={`/news/${item.slug}`}
                  className="flex items-center px-4 pb-4 gap-1 mt-4 text-th-text"
                >
                  Xem chi tiết <ArrowLeftAlt className="rotate-180 scale-75" />
                </Link>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselNavigation
          className="absolute -top-10 -right-20 left-0 w-full justify-end gap-2"
          classNameButton="bg-th-text"
          alwaysShow
        />
      </Carousel>
    </div>
  );
};
